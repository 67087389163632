<template>
  <modal name="modal-switcher" :width="650">
    <p class="m0" slot="header">
      {{ $t('Preferences') }}
    </p>
    <div slot="content">
      <div class="row">
        <div class="col-xs-12 mb10">
          {{ $t('Shop in') }}:
        </div>
        <base-select
          class="col-xs-12 h44"
          name="countries"
          :options="sortedAllowedCountries"
          :selected="selectedCountryInStoreCode"
          v-model="getSelectedCountry"
          @change="callSelectCountry()"
        />
        <div class="col-xs-12 mt20 mb10 capitalize">
          {{ $t('currency') }}:
        </div>
        <div class="col-xs-4 pointer currency active">
          <span>{{ $t(currentStoreView.i18n.currencyCode.toLowerCase() + 'Curr') }}</span>
        </div>
        <div class="col-xs-12 mt20 mb10 capitalize">
          {{ $t('language') }}:
        </div>
        <div class="col-xs-4 country mb15" v-if="getCuntryUrl(value)" :class="{ 'active': value === currentStoreView.i18n.defaultLanguage.toLowerCase() }" v-for="value in Object.keys(countryToCurrency)" :key="value">
          <a class="button-selector pointer" @click="selectLanguage(value)">
            {{ $t(value + 'Lang') }}
          </a>
        </div>
      </div>
    </div>
  </modal>
</template>
<script>
import Modal from 'theme/components/core/Modal.vue'
import config from 'config'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import BaseSelect from 'theme/components/core/blocks/Form/BaseSelect'
import { mapGetters } from 'vuex'
import CurrentPage from 'theme/mixins/currentPage'
import { selectCountry, getStoreCodeRoute } from 'theme/helpers/languageHelper'

export default {
  components: {
    Modal,
    BaseSelect
  },
  props: {
    selectedCountryInStoreCode: {
      type: String,
      default: null
    }
  },
  mixins: [CurrentPage],
  computed: {
    ...mapGetters({
      getCurrentCategory: 'category-next/getCurrentCategory',
      getCurrentProduct: 'product/getCurrentProduct',
      allowedCountries: 'ui/allowedCountries'
    }),
    getSelectedCountry: {
      get () {
        return this.$store.state.ui.selectedCountry
      },
      set (value) {
        this.$store.commit('ui/setSelectedCountry', value)
      }
    },
    sortedAllowedCountries () {
      return this.allowedCountries
        .map(item => {
          return {
            value: item.id,
            label: item.name,
            websiteId: item.websiteId
          }
        })
        .sort((a, b) => {
          if (a.label > b.label) {
            return 1
          }
          if (b.label > a.label) {
            return -1
          }
          return 0
        })
    },
    currentStoreView () {
      return currentStoreView()
    },
    countryToCurrency () {
      return config.countryToCurrency
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.$bus.$emit('modal-show', 'modal-switcher')
      this.loadCountries()
    })
  },
  methods: {
    selectLanguage (value) {
      localStorage.setItem('savedStoreCode', JSON.stringify(this.storeCodeFromCountry(value)))
      window.location.href = this.getCuntryUrl(value) + '?sc=' + this.storeCodeFromCountry(value)
    },
    callSelectCountry () {
      return selectCountry(this.allowedCountries, this.getSelectedCountry)
    },
    selectCountryFromStoreCode () {
      let storeCode = localStorage.getItem('savedStoreCode')
      if (storeCode) {
        storeCode = JSON.parse(storeCode)
        let countryId = config.storeViews[storeCode].tax.defaultCountry
        this.getSelectedCountry = countryId
      }
    },
    async selectDefaultCountry () {
      let storedItem = localStorage.getItem('savedCountry')
      if (storedItem) {
        storedItem = JSON.parse(storedItem)
      }
      let selectedWebsiteId = this.$store.state.storeView.websiteId
      let firstAvaliableCountry = ''
      for (let country of this.allowedCountries) {
        if (storedItem && (storedItem.websiteId === selectedWebsiteId) && (country.id === storedItem.id)) {
          this.getSelectedCountry = storedItem.id
          return
        } else if ((country.websiteId === selectedWebsiteId) && !firstAvaliableCountry) {
          firstAvaliableCountry = country.id
        }
      }
      if (!this.getSelectedCountry) this.getSelectedCountry = firstAvaliableCountry
    },
    async loadCountries () {
      const listOfWebsites = [1, 2, 3] // TODO: make it dynamic
      for await (let websiteId of listOfWebsites) {
        await this.$store.dispatch('ui/getAllowedCountries', websiteId)
      }
      this.selectCountryFromStoreCode()
      // this.selectDefaultCountry()
    },
    getCuntryUrl (value) {
      let storeCodeRoute = getStoreCodeRoute(this.getStoreId(value))
      if (config.storeViews[this.storeCodeFromCountry(value)]) {
        let countryUrl = config.storeViews[this.storeCodeFromCountry(value)].url
        if (storeCodeRoute) countryUrl += ('/' + storeCodeRoute)
        return countryUrl
      } else {
        return ''
      }
    },
    getStoreId (value) {
      return config.storeViews[this.storeCodeFromCountry(value)]
        ? config.storeViews[this.storeCodeFromCountry(value)].storeId
        : ''
    },
    storeCodeFromCountry (value) {
      return this.currentStoreView.i18n.currencyCode.toLowerCase() + '_' + value
    }
  }
}
</script>
<style lang="scss" scoped>
  h3 {
    margin-top: 0;
    margin-bottom: 0.5em;
  }
  .columns {
    -moz-column-count: 2;
    column-count: 2;
    column-gap: 15px;
    .country {
      -webkit-column-break-inside: avoid;
      page-break-inside: avoid;
      break-inside: avoid;
    }
  }
  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    margin-left: -1em;
    li {
      display: inline-block;
      margin-left: 1em;
      a {
        font-size: 0.9em;
      }
    }
  }
  .country a{
    color: black;
  }
  .country.active a {
    background-color: black;
    color: white;
  }
  .button-selector.disabled {
    opacity: 0.8;
  }
  .button-selector {
    padding: 10px 0px;
    width: 100%;
    text-align: center;
    border: 2px solid #191919;
    color: #030303;
    display: block;
    text-transform: uppercase;
    font-size: 14px;
  }
  /deep/.h44 select{
    height: 44px;
  }
</style>
