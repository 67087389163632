<template>
  <div class="select-wrapper relative">
    <select
      class="pointer"
      :name="name"
      :class="{
        'cl-tertiary' : options.length === 0,
        'empty': !selected
      }"
      :autocomplete="autocomplete"
      :disabled="disabled"
      @focus="$emit('focus')"
      @blur="$emit('blur')"
      @change="$emit('change', $event.target.value)"
      @input="$emit('input', $event.target.value)"
    >
      <option disabled selected value v-if="!selected" />
      <option
        v-for="(option, key) in options"
        :key="key"
        :value="option.value"
        v-bind="{selected: option.value === selected}"
      >
        {{ $t(option.label) }}
      </option>
    </select>
    <label>{{ placeholder }}</label>
    <ValidationMessages v-if="validations" :validations="validations" />
  </div>
</template>

<script>
import ValidationMessages from './ValidationMessages.vue'

export default {
  name: 'BaseSelect',
  components: {
    ValidationMessages
  },
  props: {
    id: {
      type: String,
      required: false,
      default: ''
    },
    name: {
      type: String,
      required: false,
      default: ''
    },
    options: {
      type: Array,
      required: true,
      default: () => []
    },
    selected: {
      type: String,
      required: false,
      default: ''
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    },
    autocomplete: {
      type: String,
      required: false,
      default: ''
    },
    validations: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '~theme/css/variables/colors';
  @import '~theme/css/helpers/functions/color';
  @import '~theme/css/base/text';
  $secondary-gray: color(secondary-gray);
  $gray-theme: color(gray-theme);
  $main-smoke: color(main-smoke);

select {
  font-family: 'brandon-grotesque';
}

.select-wrapper {
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 45%;
    right: 15px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 8px 6px 0 6px;
    border-color: #707070 transparent transparent transparent;
    pointer-events: none;
    @media (max-width: 767px) {
      top: 50%;
    }
  }

  select {
    position: relative;
    border: none;
    width: 100%;
    -moz-appearance: none;
    -webkit-appearance: none;
    font-family: 'Poppins';
    line-height: 20px;
    font-size: 0.8em;
    color: black;
    padding: 10px;
    border-radius: 0;
    border: 1px solid #D6D8DE;
    &:hover,
    &:focus {
      outline: none;
      border-color: #000000;
    }

    &:disabled,
    &:disabled + label {
      opacity: 0.5;
      cursor: not-allowed;
      pointer-events: none;
    }
  }
  label {
    position: absolute;
    background-color: #fff;
    color:#707070;
    top: -7px;
    left: 20px;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 0.8em;
    pointer-events:none;
    user-select: none;
  }
}
</style>
